.project-card {
    width: 80vw;
    height: min(20vh, 30vw);
    margin-top: 2vh;
    padding: 2vh;
    display: flex;
    flex-direction: row;
    font-family: 'Poppins-Bold';
    color: white;
    text-align: left;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(0,0,0,0.5);
}

.project-card.project-notimplemented {
    cursor: default;
}

.projectcard-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 2vh;
}

.projectcard-title {
    font-size: min(6vh, 5vw);
}

.projectcard-text {
    font-family: 'Poppins';
    font-size: min(2.8vh, 4.5vw);
    text-overflow: ellipsis;
    max-height: 100%;
    flex: 1;
    overflow: hidden;
    margin-top: 1vh;
}

.projectcard-image {
    height: min(20vh, 30vw);
    width: min(20vh, 30vw);
}