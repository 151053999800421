.home-header {
    background: #ff4c00;
    padding-top: 3vh;
    padding-bottom: 3vh;
    font-size: calc(10vh);
    text-align: right;
    font-family: 'Poppins-Bold';
    position: absolute;
    width: calc(100vw - 4vh);
    bottom: 10vh;
    padding-right: 4vh;
    display: flex;
    align-items: end;
    flex-direction: column;
    color: white;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 2px 2px 8px rgba(148, 50, 8, 1);
}

.home-projectscroller {
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    bottom: 55vh;
    width: 100vw;
    height: 20vh;
    
    
    display: flex;
}

.home-scrollablelist {
    display: flex;
    gap: 6vh;
    height: 100%;
    padding-right: 4vh;
    width: fit-content;
    align-items: center;
    animation: scroll 100s linear infinite;
}

.home-projectscroller:hover .home-scrollablelist {
    animation-play-state: paused !important;
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.home-projecticon {
    width: 15vh;
    height: 15vh;
}

.home-projecticon > img {
    width: 15vh;
    height: 15vh;
    border-radius: 1vh;
}

.home-header div {
    width: min-content;
}
.home-background {
    background-color: #943208;
    width: 100%;
    height: 100%;
    overflow: hidden;

    --hex-color: rgb(236, 94, 33);
    --hex-height: 100px;
    --hex-width: calc(var(--hex-height) * 26 / 15);
    --hex-gap: 20px;
    
}

.home-tiles-wrapper {
    mask-image: linear-gradient(transparent, rgb(0 0 0 / 100%) 30%);
}

.home-tiles {
    width: 100%;
    margin-top: calc(var(--hex-height) * -1);
    margin-left: calc(var(--hex-width) * -1);
}

.home-tilerow {
    display: flex;
    flex-direction: row;
    gap: var(--hex-gap);
    margin-top: calc(var(--hex-gap) * 26 / 35);
}

.home-evenrow {
    margin-left: calc(var(--hex-width) / 2 + var(--hex-gap) / 2);
}

.home-hex:before {
    content: " ";
    width: 0; height: 0;
    border-bottom: calc(var(--hex-height) / 2) solid var(--hex-color);
    border-left: calc(var(--hex-width) / 2) solid transparent;
    border-right: calc(var(--hex-width) / 2) solid transparent;
    position: absolute;
    top: calc(var(--hex-height) / -2);
    left: 0;
}

.home-hex {

    margin-top: calc(var(--hex-height) / 2);
    min-width: var(--hex-width);
    height: var(--hex-height);
    background-color: var(--hex-color);
    position: relative;
}

.home-hex:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: calc(var(--hex-height) / -2);
    left: 0;
    border-top: calc(var(--hex-height) / 2) solid var(--hex-color);
    border-left: calc(var(--hex-width) / 2) solid transparent;
    border-right: calc(var(--hex-width) / 2) solid transparent;
}

@media (max-width: 800px) {
    .home-background {
        --hex-color: rgb(236, 94, 33);
        --hex-height: 52px;
        --hex-width: calc(var(--hex-height) * 26 / 15);
        --hex-gap: 10px;
        
    }

    .home-header {
        bottom: 20vh;
        
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: calc(6vh);

        padding-right: 2.5vh;
        width: calc(100vw - 2.5vh);
    }

    .home-projectscroller {
        bottom: 50vh;
        height: 15vh;
    }

    .home-scrollablelist {
        gap: 4vh;
    }

    .home-projecticon {
        width: 10vh;
        height: 10vh;
    }
    
    .home-projecticon > img {
        width: 10vh;
        height: 10vh;
        border-radius: 1vh;
    }
}