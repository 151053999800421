

.App-header {
    height: 6vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vh;
    color: white;
    user-select: none;
  }
  
  .App-clickables {
    flex: 5;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .App-colorbar {
    flex: 1;
    width: 100%;
  }
  
  .App-navbox {
    flex: 1;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-family: 'Jura';
    background-color: #333;
    cursor: pointer;
  }
  
  .App-navbox:hover {
    background-color: black;
  }
  
  .App-navbox.clicked {
    background: none;
  }
  
  /* Color-animated cover bar */
  
  @property --pos {
      syntax: '<length-percentage>';
      initial-value: 0%;
      inherits: false
  }
  
  #App-coverbar {
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    background-color: #333;
    z-index: -100;
  }
  
  @keyframes colorbar { 0% { --pos: 0 } 90%, 100% { --pos: 100% } }

/* Default colors */

#App-coverbar.clickedHome {
    background-color: #ff4c00;
}
#App-coverbar.clickedResume {
    background-color: #43a5ff;
}
#App-coverbar.clickedProjects {
    background-color: #b42fc9;
}
#App-coverbar.clickedContact {
    background-color: #299b29;
}