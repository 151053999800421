@font-face {
  font-family: 'Jura';
  src: local('Jura'), url(./fonts/Jura/static/Jura-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  overflow-y: none;
  display: flex;
  flex-direction: column;
}

.page-main {
  overflow-y: auto;
  flex: 1;
}

.project-header {
  padding-top: 4vh;
  font-size: 8vh;
  font-family: 'Poppins-Bold';
  line-height: 1;
  margin-bottom: 0.5em;
}

.project-subheader {
  font-size: calc(2vh + 0.5vw);
  font-family: 'Poppins-Italic';
}

.project-background {
  min-height: 100%;
  width: 100%;
  padding: calc(20vw - 2vh);
  padding-top: 6vw;
  font-size: 2vh;
  box-sizing: border-box;
  font-family: 'Poppins';
  color: white;
}

.project-text {
  margin-top: 4vh;
}

.project-image {
  margin-top: 4vh;
}

.project-image > img {
  height: calc(30vh + 12vw);
}

.project-image > div {
  margin-top: 0.5vh;
  font-family: 'Poppins-Italic';
}

.project-mainflow {
  display: grid;
  grid-template-columns: 100%;
}

button {
  font-family: Poppins;
}

.inline-icon {
  font-size: 1em;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  padding: 0.25em;
  font-family: Arial;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-weight: bold;
  margin-right: 1em;
}

.warning-icon {
  background-color: #ffa200;
}

.error-icon {
  background-color: #ff0000;
}

@media (min-width: 1200px) {
  .project-mainflow {
    grid-template-columns: 40vw 24vw;
  }
  .project-image {
    grid-column: 2;
  }
  .project-text {
    grid-column: 1;
    text-align: left;
  }

  .project-image > img {
    height: calc(2vh + 20vw);
  }

}