.ghostjazz-background {
    background-color: #379674;
}

a:link {
    color: white;
    text-decoration: underline;
}

a:visited {
    color: #ddd;
}
.project-overlay {
    position: absolute;
    width: 100vw;
    height: 20px;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(255,255,255,0));
    z-index: 100;
}