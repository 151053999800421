.resume-frame {
    width: 80%;height: 100%;border: none;
}

.resumepage-background {
    background-color: #296399;
    min-height: 100%;
}

.resume-container {
    display: inline-block;
    vertical-align:top;
}

.resume-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: min(100vh, calc(100vw - 12vh)); */
    /* width: 100%; */
    /* height: 100%; */
    padding: 6vh;
}

.embed-container {
    width: min(100vh, 80vw);
    height: min(130vh, 104vw);
    background-color: white;
}

.resume-preheader {
    height: 0;
}

.resume-downloadbutton {
    background-color: #43a5ff;
    border: none;
    margin-top: 4vh;
    width: 20vh;
    height: 8vh;
    border-radius: 4px;
    color: white;
    font-size: 3vh;
    font-family: 'Poppins';
    align-self: center;
    cursor: pointer;
}

.resume-downloadbutton:hover {
    background-color: #53acff;
}

.resume-headertext {
    font-family: 'Poppins-Bold';
    font-size: 7vw;
    line-height: 7vw;
    color: white;
    width: min-content;
    max-width: 100%;
    text-align: left;
}

.resume-img {
    position: absolute;
    right: calc(-10vw);
    top: calc(60vh - 20vw);
    width: calc(40vh + 20vw);
    box-shadow: 0 0 10vh rgba(0,0,0,0.6);
    border-radius: 10px;
}

.resume-things {
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    margin-top: 5vh;
}

.react-pdf__Page__canvas {
    width: min(100vh, 80vw) !important;
    height: auto !important;
}

@media (min-width: 1200px) {
    .resume-background {
        flex-direction: row;
        align-items: start;
    }
    .resume-things {
        margin-left: 20px;
        text-align: center;
        white-space: normal;
        margin-top: 20vh;
    }
}

.react-pdf__Document {
    width: min-content;
}

.react-pdf__Page {
    width: min-content !important;
    --scale-factor: auto !important;
}

.resume-circles-wrapper {
    position: relative;
}

.resume-circles {
    position: absolute;
}

.resume-circlerow {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.resume-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}
